import React from 'react';
import { Allergens, FreeFroms } from '../../../../../../store/menus';
import './AllergensPopup.scss';
import SelectionMenu from "../SelectionMenu";
import { Card } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCircle, faExclamation, faM, faTimes, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

export const AllergensPopup = (props) => {
  const [lastClicked, setLastClicked] = React.useState(null);

  const {
    item,
    toggle,
    deleteItem,
    onHiddenBranchesUpdate,
    menu,
    restoreMenuItem,
    update,
    branches,
  } = props;

  const {name, notes, visible, ingredients} = item;

  const onTogglePrivacy = (i) => {
    update({
      id: item.id,
      visible: !item.visible,
    });
  };

  const onCancel = () => {
    if (restoreMenuItem({menuId: menu.id, itemId: item.id})) {
      toggle();
    }
  };

  const onClickAway = () => setLastClicked(null);

  const onSave = () => {
    toggle();
  };

  const onNotesChange = (i) => {
    update({
      id: item.id,
      notes: i.target.value,
    });
  };

  const onIngredientsChange = (i) => {
    update({
      id: item.id,
      ingredients: i.target.value,
    });
  };

  return <React.Fragment>
    <div className="AllergensPopup" onClick={onClickAway}>
      <div className="top-bar">
        <div className="row w-100">
          <p className="col-12 col-md-6 title">
            {name}
          </p>

          <div className="col-12 col-md-6 mt-3 mt-md-0  btn-group btn-group-sm">
            <button className="btn btn-outline-danger px-4" onClick={deleteItem}>
              <FontAwesomeIcon icon={faTrashAlt}/>
            </button>
            {
              menu.edited ? <>
                <button className="btn btn-outline-danger" onClick={onCancel}>Cancel changes</button>
                <button className="btn btn-primary" onClick={onSave}>Save & Close</button>
              </> : <>
                <button className="btn btn-outline-secondary" onClick={toggle}>Close</button>
              </>
            }
          </div>
        </div>

      </div>

      <div className="row p-0">
        <div className="col col-12 col-sm-12 col-md-12 col-lg-8 order-2 order-lg-1 gap-2 ">
          <Card>
            <Card.Header>Suitability & Allergens</Card.Header>
            <Card.Body>
              <div className="allergens-list">
                <p className="section-title">Suitable for</p>
                <div className="allergens-container">

                  <div className="allergen-row d-grid grid-cols-4">
                    <div className="blank-cell">&nbsp;</div>
                    <div className="option-cell fw-bold">No</div>
                    <div className={"option-cell fw-bold"}>Modifiable</div>
                    <div className="option-cell fw-bold">Yes</div>
                  </div>
                  {Object.keys(FreeFroms).map(a => {
                    const title = FreeFroms[a];
                    const value = item[a];

                    const onChange = (newVal) => update({
                      id: item.id,
                      [a]: newVal,
                    });

                    const onClick = (val) => {
                      setLastClicked(a);
                      onChange(val);
                    };

                    let colours = {
                      "no": "red",
                      "modifiable": "blue",
                      "yes": "green",
                    };

                    return <React.Fragment key={a}>
                      <div className={`allergen-row ${colours[value]} ${lastClicked === a ? 'last-clicked' : ''}  d-grid grid-cols-4`} onClick={onClickAway}>
                        <p className="label" onClick={() => onClickAway()}>{title}</p>
                        <div
                          className={`option-cell red ${value === "no" ? "active" : 'inactive'}`} onClick={(e) => {
                          e.stopPropagation();
                          onClick("no");
                        }}
                        >
                          <span className="fa-layers fa-fw">
                            <FontAwesomeIcon icon={faCircle} size={"lg"}/>
                            <FontAwesomeIcon icon={faTimes} transform={'shrink-2'}/>
                          </span>
                        </div>
                        <div
                          className={`option-cell blue ${value === "modifiable" ? "active" : 'inactive'}`} onClick={(e) => {
                          e.stopPropagation();
                          onClick("modifiable");
                        }}
                        >
                          <span className="fa-layers fa-fw">
                            <FontAwesomeIcon icon={faCircle} size={"lg"}/>
                            <FontAwesomeIcon icon={faM} transform={'shrink-4'}/>
                          </span>
                        </div>
                        <div
                          className={`option-cell green ${value === "yes" ? "active" : 'inactive'}`} onClick={(e) => {
                          e.stopPropagation();
                          onClick("yes");
                        }}
                        >
                          <span className="fa-layers fa-fw">
                            <FontAwesomeIcon icon={faCircle} size={"lg"}/>
                            <FontAwesomeIcon icon={faCheck} transform={'shrink-2'}/>
                          </span>
                        </div>
                      </div>
                    </React.Fragment>;
                  })}
                </div>
              </div>
              <div className="divider horizontal my-4"></div>
              <div className="allergens-list">
                <p className="section-title">Allergens</p>

                <div className="allergens-container">

                  <div className="allergen-header-row d-grid grid-cols-5">
                    <div className={"blank-cell"}>&nbsp;</div>
                    <div className={"option-cell fw-bold"}>Contains</div>
                    <div className={"option-cell fw-bold"}>Cross contamination</div>
                    <div className={"option-cell fw-bold"}>Modifiable</div>
                    <div className={"option-cell fw-bold"}>Free from</div>
                  </div>

                  {Object.keys(Allergens).map(a => {
                    const title = Allergens[a];
                    const value = item[a];

                    const onChange = newValue => update({
                      id: item.id,
                      [a]: newValue,
                    });

                    const onClick = (val) => {
                      setLastClicked(a);
                      onChange(val);
                    };

                    let colours = {
                      "contains": "red",
                      "cross_contamination": "orange",
                      "modifiable": "blue",
                      "free_from": "green",
                    };

                    let valueActive = (staticVal, value) => {
                      if (staticVal === value) {
                        return `active`;
                      } else {
                        return `inactive`;
                      }
                    };

                    return <React.Fragment key={a}>
                      <div className={`allergen-row ${colours[value]} ${lastClicked === a ? 'last-clicked' : ''}  d-grid grid-cols-5`} onClick={onClickAway}>
                        <p className="label" onClick={() => onClickAway()}>{title}</p>
                        <div
                          className={`option-cell red ${valueActive("contains", value)}`} onClick={(e) => {
                          e.stopPropagation();
                          onClick("contains");
                        }}
                        >
                          <span className="fa-layers fa-fw">
                            <FontAwesomeIcon icon={faCircle} size={"lg"}/>
                            <FontAwesomeIcon icon={faTimes} transform={'shrink-2'}/>
                          </span>
                        </div>
                        <div
                          className={`option-cell orange ${valueActive("cross_contamination", value)}`} onClick={(e) => {
                          e.stopPropagation();
                          onClick("cross_contamination");
                        }}
                        >
                          <span className="fa-layers fa-fw">
                            <FontAwesomeIcon icon={faCircle} size={"lg"}/>
                            <FontAwesomeIcon icon={faExclamation} transform={'shrink-2'}/>
                          </span>
                        </div>
                        <div
                          className={`option-cell blue ${valueActive("modifiable", value)}`} onClick={(e) => {
                          e.stopPropagation();
                          onClick("modifiable");
                        }}
                        >
                          <span className="fa-layers fa-fw">
                            <FontAwesomeIcon icon={faCircle} size={"lg"}/>
                            <FontAwesomeIcon icon={faM} transform={'shrink-4'}/>
                          </span>
                        </div>
                        <div
                          className={`option-cell green ${valueActive("free_from", value)}`} onClick={(e) => {
                          e.stopPropagation();
                          onClick("free_from");
                        }}
                        >
                          <span className="fa-layers fa-fw">
                            <FontAwesomeIcon icon={faCircle} size={"lg"}/>
                            <FontAwesomeIcon icon={faCheck} transform={'shrink-2'}/>
                          </span>
                        </div>
                      </div>

                    </React.Fragment>;
                  })}
                </div>
              </div>
            </Card.Body>
          </Card>

        </div>
        <div className="col col-12 col-sm-12 col-md-12 col-lg-4 order-1 order-lg-2">
          <div className="d-flex flex-column gap-2">
            <Card>
              <Card.Header>Location availability</Card.Header>
              <Card.Body>
                <SelectionMenu
                  options={branches}
                  hidden={item.hidden_branches}
                  onItemChange={onHiddenBranchesUpdate}
                />
              </Card.Body>
            </Card>

            <Card>
              <Card.Header>Ingredients</Card.Header>
              <Card.Body>
                <textarea
                  className="form-control"
                  placeholder="Add ingredients here"
                  value={ingredients || ""}
                  onChange={onIngredientsChange}
                />
              </Card.Body>
            </Card>

            <Card>
              <Card.Header>Notes</Card.Header>
              <Card.Body>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Add a note here"
                  value={notes || ""}
                  onChange={onNotesChange}
                />
              </Card.Body>
            </Card>

            <Card>
              <Card.Header>Settings</Card.Header>
              <Card.Body>
                <div className="private btn-group btn-group-sm">
                  <button
                    onClick={onTogglePrivacy}
                    className={`btn ${visible ? 'btn-outline-secondary' : 'btn-primary'}`}
                  >
                    Private
                  </button>

                  <button
                    onClick={onTogglePrivacy}
                    className={`btn ${visible ? 'btn-primary' : 'btn-outline-secondary'}`}
                  >
                    Public
                  </button>
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </div>
  </React.Fragment>;
};

export default AllergensPopup;