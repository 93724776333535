import React from 'react';
import { createMenu, reorderMenus } from '../../store/menus';
import { connect } from 'react-redux';
import AddIcon from '../../assets/images/add.png';
import Logo from '../../assets/images/logo.png';
import Invisible from '../../assets/images/invisible.png';
import InvisibleWhite from '../../assets/images/invisible-white.png';
import { Link, NavLink, useHistory } from 'react-router-dom';
import UpArrow from '../../assets/images/up-arrow.png';
import DownArrow from '../../assets/images/down-arrow.png';
import { axios } from "../../utilities/axios";
import './PageLayout.scss';
import EnvRibbon from "../StagingRibbon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

const PageLayout = (props) => {
  let { addToast, user } = props;
  let history = useHistory();

  const reorderMenus = (params) => {
    const { user, menus } = props;
    const auth = { username: user.email, password: user.auth_token };
    const { id, sorting_order } = params;

    const reordered = menus.sort((a, b) => {
      if (a.id === id) {
        return sorting_order - b.sorting_order;
      } else if (b.id === id) {
        return a.sorting_order - sorting_order;
      } else {
        return a.sorting_order - b.sorting_order;
      }
    });

    axios.post('/api/business/v1/menus/reorder', { menu_ids: reordered.map(x => x.id) }, { auth })
      .then(res => props.reorderMenus({ menus: res.data }))
      .catch(err => {
        let errors = err.response.data.errors;
        addToast("An error occurred updating the menus order", errors.join('\n'));
      });
  };

  const renderMenuLink = (m) => {
    const active = (
      props.match ? props.match.params.id : "INVALID"
    ) === m.id;
    const icon = active ? InvisibleWhite : Invisible;

    let right = null;
    if (m.edited) {
      right =
        <p>
          <span role={"img"} aria-label={"Menu has been modified"}>❗</span>
        </p>;
    } else if (!m.visible) {
      right = <img className="logo" src={icon} alt="Menu Hidden"/>;
    } else {
      right = (
        <div className="arrows">
          <img
            alt={"Sort Up"} src={UpArrow} onClick={() => reorderMenus({
            id: m.id, sorting_order: m.sorting_order - 2,
          })}
          />
          <img
            alt={"Sort Down"} src={DownArrow} onClick={() => reorderMenus({
            id: m.id, sorting_order: m.sorting_order + 2,
          })}
          />
        </div>
      );
    }

    return (
      <NavLink className="menu-nav" key={m.id} to={`/menus/${m.id}`}>
        {m.name || "-"} {right}
      </NavLink>
    );
  };

  const createMenu = () => {
    const params = {
      name: "New Menu",
      visible: false,
    };

    const url = '/api/business/v1/menus/';
    const auth = { username: user.email, password: user.auth_token };
    axios.post(url, { menu: params }, { auth })
      .then((res) => {
        let updatedMenu = {
          edited: false,
          ...res.data,
        };

        props.createMenu(updatedMenu);
        history.push('/menus/' + updatedMenu.id);
        return true;
      })
      .catch((err) => {
        let response = err.response;
        let errors = response.data.errors;
        addToast("An error occurred creating the menu", errors.join('\n'));
      });
    return true;
  };

  const sidebarOpen = props.sidebarOpen || false;
  const setSidebarOpen = props.setSidebarOpen;
  const toggleSidebar = () => setSidebarOpen(!sidebarOpen);

  return (
    <div className="PageLayout">
      <div className={["sidebar", sidebarOpen ? 'open' : ''].join(' ')}>
        {setSidebarOpen && (
          <div className="d-flex d-lg-none flex-column justify-content-between align-items-center mb-4">
            <button className="btn btn-light d-flex align-items-center align-content-center gap-2" onClick={toggleSidebar}>
              <FontAwesomeIcon icon={faXmark}/> Close Menu
            </button>
          </div>
        )}

        <Link to="/">
          <img src={Logo} alt="Logo"/>
        </Link> <EnvRibbon/>

        <div className="nav">
          <div className="nav-section">
            <h4>Your Menus</h4>
            {props.menus.map(renderMenuLink)}
            <button className="new-menu" onClick={createMenu}>New Menu
              <img className="logo" src={AddIcon} alt="New Menu"/>
            </button>
          </div>

          <div className="nav-section">
            <h4>Other</h4>
            <a href="mailto:support@dietarymenu.com">Support</a>
            <button onClick={props.logout}>Logout</button>
          </div>
        </div>
      </div>

      <div className={`content ${props.title}`}>
        {props.children}
      </div>
    </div>
  );
};

const mapStateToProps = state => (
  {
    menus: Object.values(state.menus).sort((a, b) => a.sorting_order - b.sorting_order),
    user: state.auth.user,
  }
);

const mapDispatchToProps = (dispatch, props) => {
  return (
    {
      createMenu: (menu) => dispatch(createMenu(menu)),
      reorderMenus: (menus) => dispatch(reorderMenus(menus)),
      logout: () => dispatch({ type: 'AUTH_LOGOUT' }),
    }
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PageLayout);
