import uuidv4 from "uuid/v4";

export const createMenu = (payload) => (
  { type: "CREATE_MENU", payload }
);
export const updateMenu = (payload) => (
  { type: "UPDATE_MENU", payload }
);
export const restoreMenu = (payload) => (
  { type: "RESTORE_MENU", payload }
);
export const cacheMenu = (payload) => (
  { type: "CACHE_MENU", payload }
);
export const restoreMenuItem = (payload) => (
  { type: "RESTORE_MENU_ITEM", payload }
);
export const reorderMenus = (payload) => (
  { type: "REORDER_MENUS", payload }
);
export const reorderMenuCategory = (payload) => (
  { type: "REORDER_MENU_CATEGORY", payload }
);
export const updateMenuCategory = (payload) => (
  { type: "UPDATE_MENU_CATEGORY", payload }
);
export const deleteMenuCategory = (payload) => (
  { type: "DELETE_MENU_CATEGORY", payload }
);
export const createMenuCategory = (payload) => (
  { type: "CREATE_MENU_CATEGORY", payload }
);
export const updateMenuItem = payload => (
  { type: "UPDATE_MENU_ITEM", payload }
);
export const createMenuItem = (payload) => (
  { type: "CREATE_MENU_ITEM", payload }
);
export const deleteMenuItem = (payload) => (
  { type: "DELETE_MENU_ITEM", payload }
);
export const deleteMenu = (payload) => (
  { type: "DELETE_MENU", payload }
);

export const Allergens = {
  "contains_celery": "Celery",
  "contains_cereals_with_gluten": "Cereals w Gluten",
  "contains_crustaceans": "Crustaceans",
  "contains_eggs": "Eggs",
  "contains_fish": "Fish",
  "contains_lupin": "Lupin",
  "contains_milk": "Milk",
  "contains_molluscs": "Molluscs",
  "contains_mustard": "Mustard",
  "contains_nuts": "Nuts",
  "contains_peanuts": "Peanuts",
  "contains_sesame_seeds": "Sesame Seeds",
  "contains_soya": "Soya",
  "contains_sulphite": "Sulphite",
};

export const FreeFroms = {
  "vegetarian_friendly": "Vegetarian",
  "vegan_friendly": "Vegan",
  // "gluten_friendly": "Gluten",
};

function saveMenuToLocalStorage(menu) {
  localStorage.setItem(`menu-${menu.id}`, JSON.stringify(menu));
}

function retrieveMenuFromLocalStorage(id) {
  return JSON.parse(localStorage.getItem(`menu-${id}`));
}

const menusReducer = (state = {}, action) => {
  const payload = action.payload;
  switch (action.type) {
    case "AUTH_SET_USER": {
      let menus = {};

      const data = payload.business.menus;
      data.forEach(m => {
        let menu = { ...m, edited: false };
        saveMenuToLocalStorage(menu);
        return menus[m.id] = menu;
      });

      return menus;
    }
    case "AUTH_LOGOUT": {
      localStorage.clear();
      return {};
    }
    case "CREATE_MENU": {
      const menu = {
        categories: [],
        ...payload,
      };
      saveMenuToLocalStorage(menu);
      return {
        ...state,
        [menu.id]: menu,
      };
    }
    case "UPDATE_MENU": {
      let menus = {};

      Object.values(state).forEach(m => {
        if (payload.id !== m.id) {
          menus[m.id] = m;
        } else {
          const edited = payload.visible === undefined ? true : (
            menus[m.id] ? menus[m.id].edited : false
          );
          menus[m.id] = {
            ...m,
            edited,
            ...payload,
          };
        }
      });

      return menus;
    }
    case "CACHE_MENU": {
      Object.values(state).forEach(m => {
        if (payload.id === m.id) {
          saveMenuToLocalStorage(m);
        }
      });

      return state;
    }
    case "RESTORE_MENU": {
      let menus = {};

      Object.values(state).forEach(m => {
        if (payload.id !== m.id) {
          menus[m.id] = m;
        } else {
          const menu = retrieveMenuFromLocalStorage(payload.id);
          if (menu) {
            menus[m.id] = { ...m, ...menu, edited: false };
          } else {
            menus[m.id] = m;
          }
        }
      });

      return menus;
    }
    case "RESTORE_MENU_ITEM": {
      let menus = {};

      let menuId = payload.menuId;
      let itemId = payload.itemId;
      const restoredMenu = retrieveMenuFromLocalStorage(menuId);

      let itemToRestore = null;
      let found = false;
      if (!restoredMenu) {
        console.error("Could not retrieve menu");
        return state;
      }
      restoredMenu.categories.forEach(category => {
        if (found) {
          return;
        }
        category.items.forEach(item => {
          if (found) {
            return;
          }
          if (item.id === itemId) {
            itemToRestore = item;
            found = true;
          }
        });
      });
      if (!found) {
        console.error("Could not find item to restore");
        return state;
      }
      delete itemToRestore._destroy;

      Object.values(state).forEach(m => {
        let menu = {
          ...m, categories: [
            ...m.categories.map(c => (
              {
                ...c,
                items: [
                  ...c.items,
                ],
              }
            )),
          ],
        };

        m.categories.forEach((c, cIndex) => {
          c.items.forEach((item, itemIndex) => {
            if (item.id !== itemId) {
              return;
            }

            menu.categories[cIndex].items[itemIndex] = {
              ...item,
              ...itemToRestore,
            };
          });
        });

        menus[m.id] = menu;
      });

      return menus;
    }
    case "UPDATE_MENU_CATEGORY": {
      let menus = {};

      Object.values(state).forEach(m => {
        let menu = { ...m, categories: [...m.categories] };

        m.categories.forEach((c, i) => {
          if (c.id !== payload.id) {
            return;
          }

          menu.edited = true;
          menu.categories[i] = { ...c, ...payload };
        });

        menus[m.id] = menu;
      });

      return menus;
    }
    case "REORDER_MENU_CATEGORY": {
      let menus = {};
      Object.values(state).forEach(m => {
        let menu = { ...m, categories: [...m.categories] };

        const category = m.categories.find(c => c.id === payload.id);
        if (!category) {
          menus[m.id] = menu;
          return;
        }

        const isIncrement = category.sorting_order < payload.sorting_order;
        const sortingOrders = m.categories.map(c => c.sorting_order);

        // Don't increment if already the highest sorting order
        if (isIncrement && Math.max(...sortingOrders) === category.sorting_order) {
          menus[m.id] = menu;
          return;
        }
        // Don't decrement if already the lowest sorting order
        if (!isIncrement && Math.min(...sortingOrders) === category.sorting_order) {
          menus[m.id] = menu;
          return;
        }

        m.categories.forEach((c, i) => {
          if (c.id === payload.id) {
            menu.categories[i] = { ...c, sorting_order: payload.sorting_order };
          } else if (c.sorting_order === payload.sorting_order) {
            menu.categories[i] =
              {
                ...c,
                sorting_order: (
                  c.sorting_order + (
                    isIncrement ? -1 : 1
                  )
                ),
              };
          }
        });

        menus[m.id] = {
          ...menu,
          edited: true,
        };
      });

      return menus;
    }
    case "DELETE_MENU_CATEGORY": {
      let menus = {};

      Object.values(state).forEach(m => {
        let menuEdited = false;
        menus[m.id] = {
          ...m,
          categories: m.categories.map(c => {
            if (c.id !== payload.id) {
              return c;
            }
            menuEdited = true;

            return {
              ...c,
              ...(
                c.id === payload.id ? { _destroy: true } : {}
              ),
            };
          }),
          edited: menuEdited,
        };
      });

      return menus;
    }
    case "DELETE_MENU": {
      let menus = {};
      for (let id in state) {
        if (id !== payload.id) {
          menus[id] = state[id];
        }
      }
      return menus;
    }
    case "REORDER_MENUS": {
      let menus = {};
      for (let menu of payload.menus) {
        menus[menu.id] = menu;
      }
      return menus;
    }
    case "CREATE_MENU_CATEGORY": {
      let menus = {};

      Object.values(state).forEach(m => {
        if (payload.id !== m.id) {
          menus[m.id] = m;
          return;
        }

        const sorting_order = Math.min(...m.categories.map(c => c.sorting_order)) - 1;
        const category = { id: null, sorting_order, name: '', items: [] };

        menus[m.id] = {
          ...m,
          edited: true,
          categories: [
            ...m.categories,
            category,
          ],
        };
      });

      return menus;
    }
    case "CREATE_MENU_ITEM": {
      let menus = {};

      Object.values(state).forEach(m => {
        let menu = {
          ...m,
          categories: [
            ...m.categories,
          ],
        };

        m.categories.forEach((c, i) => {
          if (c.id !== payload.id) {
            return;
          }

          let newItem = {
            id: `new-${uuidv4()}`,
          }
          Object.keys(FreeFroms).forEach(a => newItem[a] = "yes");
          Object.keys(Allergens).forEach(a => newItem[a] = "free_from");
          menu.edited = true;
          menu.categories[i] = {
            ...c,
            items: [...c.items, newItem],
          };
        });

        menus[m.id] = menu;
      });

      return menus;
    }
    case "UPDATE_MENU_ITEM": {
      let menus = {};

      Object.values(state).forEach(m => {
        let menu = {
          ...m,
          categories: [
            ...m.categories.map(c => (
              {
                ...c,
                items: [...c.items],
              }
            )),
          ],
        };

        m.categories.forEach((c, cIndex) => {
          c.items.forEach((item, itemIndex) => {
            if (item.id !== payload.id) {
              return;
            }

            menu.edited = true;
            menu.categories[cIndex].items[itemIndex] = { ...item, ...payload };
          });
        });

        menus[m.id] = menu;
      });

      return menus;
    }
    case "DELETE_MENU_ITEM": {
      let menus = {};

      Object.values(state).forEach(m => {
        let modified = false;
        let menu = {
          ...m,
          categories: m.categories.map(c => (
            {
              ...c,
              items: c.items.map(i => {
                let itemToModify = i.id === payload.id;
                if (itemToModify) {
                  modified = true;
                }
                return {
                  ...i,
                  ...(
                    itemToModify ? { _destroy: true } : {}
                  ),
                };
              }),
            }
          )),
        };
        if (modified) {
          menu.edited = true;
        }

        menus[m.id] = menu;
      });

      return menus;
    }
    case "FETCH_BRANCHES": {
      return {
        ...state,
        branches: payload.branches,
      };
    }
    default :
      return state;
  }
};

export const validateMenu = menu => {
  let errors = [];

  if (menu.name === "") {
    errors.push("Menu is missing a name");
  }

  menu.categories.forEach(category => {
    if (!!category._destroy) {
      return;
    }

    if (category.name === "") {
      errors.push(`A category is missing a name`);
    }

    category.items.forEach(item => {
      if (!!item._destroy) {
        return;
      }
      const additionalDetail = `in the ${category.name || ''} category.`;

      if (item.name === "") {
        errors.push(`An item is missing a name, ${additionalDetail}`);
      }

      Object.keys(Allergens).forEach(a => {
        if (!item[a]) {
          errors.push(`An item named '${item.name || ''}' is missing a value for ${Allergens[a]} , ${additionalDetail}`);
        }
      });

      Object.keys(FreeFroms).forEach(a => {
        if (item[a] === undefined || item[a] === null) {
          errors.push(`An item named '${item.name || ''}' is missing a value for ${FreeFroms[a]} friendly, ${additionalDetail}`);
        }
      });
    });
  });

  return errors;
};

export default menusReducer;
